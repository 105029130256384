import React, { useContext } from "react";
import tw, { styled } from "twin.macro";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
	setComment,
	resetFeedback,
	doSubmit,
} from "../../store/actions/feedback";
import { ThemeContext } from "../themes/theme-context";

const StyledButton = styled.button(({ textColor }) => [
	tw`text-sm leading-sans-sm underline lg:text-base lg:leading-sans-base hover:text-black`,
	textColor,
]);

const FeedbackComment = ({ data, resourceId, commentStyles }) => {
	const dispatch = useDispatch();
	const { theme } = useContext(ThemeContext);

	const state = useSelector((state) =>
		state.feedback.current ? state.feedback.current : null
	);

	const { executeRecaptcha } = useGoogleReCaptcha();

	const [comment, setLocalComment] = useState(state.comment);

	const handleComment = (e) => {
		dispatch(setComment(resourceId, e.target.value));
		setLocalComment(e.target.value);
	};

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();

			if (!executeRecaptcha) {
				return;
			}

			const result = await executeRecaptcha("feedback");
			dispatch(doSubmit(resourceId, true, result));
		},
		[executeRecaptcha, dispatch, resourceId]
	);

	// const handleSkip = () => {
	// 	dispatch(resetFeedback(resourceId));
	// };

	return (
		<div css={commentStyles.styledComment}>
			<h4 css={commentStyles.styledLabel}>{data.commentLabel}</h4>
			<form css={commentStyles.styledForm} onSubmit={handleSubmit}>
				<textarea
					css={[commentStyles.styledInput, theme.inputBorder]}
					value={comment}
					onChange={handleComment}
					aria-label={data.commentLabel}
				/>
				<div
					css={commentStyles.styledButtons ? commentStyles.styledButtons : ""}
				>
					<StyledButton type="submit" css={theme.textColor}>
						{data.submitButtonLabel}
					</StyledButton>
					<StyledButton css={theme.textColor} onClick={handleSubmit}>
						{data.skipButtonLabel}
					</StyledButton>
				</div>
			</form>
		</div>
	);
};

export default FeedbackComment;
